.button {
  @include transition-fast;
  position: relative;
  padding: 8px 14px;
  display: inline-block;
  background-color: transparent;
  color: $color-text;
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  border: 1px solid $color-border-tertiary;

  svg {
    @include transition-fast;
    position: relative;
    top: 3px;
    fill: $color-text;

    & + span {
      margin-left: 6px;
    }
  }

  span + svg {
    margin-left: 12px;
  }

  &:hover,
  &:active {
    background-color: $color-bg-tertiary;
    color: $color-text-white;

    svg {
      fill: $color-text-white;
    }
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 1px $color-border-tertiary,
      inset 0 0 0 2px $color-bg-snow;
  }
}
