$font-primary: "Source Sans Pro", sans-serif;

@font-face {
  font-family: "Source Sans Pro";
  src: url("../../assets/fonts/SourceSansPro/SourceSansPro-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../../assets/fonts/SourceSansPro/SourceSansPro-Bold.woff")
    format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../../assets/fonts/SourceSansPro/SourceSansPro-Italic.woff")
    format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../../assets/fonts/SourceSansPro/SourceSansPro-Black.woff")
    format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: fallback;
}
