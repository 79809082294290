.card-article {
  position: relative;
  display: flex;

  &__link {
    @include position-absolute;
    display: block;

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 104%;
      height: 112%;
      display: inline-block;
      content: '';
      opacity: 0;
      transform: translate(-50%, -50%);
    }

    &:hover {
      & + .card-article__title {
        color: $color-text-1;
      }
    }

    &:focus-visible {
      &:after {
        opacity: 1;
      }
    }
  }

  &__info {
    margin-top: 6px;
  }

  &__date {
    display: inline-block;
    color: $color-text-3;
    font-size: 14px;
    line-height: 16px;
  }

  &__category {
    margin-left: 16px;
    padding: 2px 10px;
    display: inline-block;
    border: 1px solid $color-border-stone;
    border-radius: 12px;
    background-color: $color-bg-light;
    font-size: 12px;
    line-height: 12px;
  }

  &__image {
    position: relative;
    text-align: center;
  }

  &__content {
    position: relative;
    flex: 1;
  }

  &__title {
    @include transition-fast;
    font-size: 18px;
    line-height: 26px;
    color: $color-text-4;
  }

  &--image {
    .card-article {
      &__image {
        img {
          position: absolute;
          height: 0;
          opacity: 0;
        }
      }
    }
  }

  &--small {
    .card-article {
      &__image {
        width: 70px;
        max-height: 54px;
        overflow: hidden;
        border-radius: 8px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      &__content {
        margin-left: 12px;
      }

      &__link {
        &:after {
          border: 1px solid $color-border-tertiary;
          border-radius: 4px;
        }
      }
    }
  }

  &--large {
    border-radius: 12px;
    overflow: hidden;
    flex-direction: column;

    .card-article {
      &__image {
        height: 125px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        & + .card-article__content {
          .card-article__link {
            border-radius: 0 0 12px 12px;
          }
        }
      }

      &__content {
        padding: 20px 24px;
      }

      &__title {
        margin-bottom: 16px;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
      }

      &__link {
        border: 1px solid $color-border-stone;
        border-radius: 12px;
        overflow: hidden;

        &:focus-visible {
          border-color: $color-border-tertiary;
        }
      }
    }
  }

  @include size-md {
    &--small {
      .card-article {
        &__image {
          width: 108px;
          max-height: 82px;
        }
      }
    }
    
    &--large {
      .card-article {
        &__image {
          height: 200px;
        }
      }
    }
  }

  @include size-lg {
    &--large {
      .card-article {
        &__image {
          height: 288px;
        }
      }
    }
  }
}
