.link {
    @include transition-fast;
    position: relative;
    margin: 0 -10px;
    padding: 0 10px;
    display: inline-block;
    color: $color-secondary;
    font-size: 16px;
    line-height: 24px;
  
    &:after {
      @include transition-fast;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      display: inline-block;
      border: 2px solid $color-border-secondary-hover;
      border-radius: 4px;
      content: '';
      opacity: 0;
      transform: translate(-50%, -50%);
    }
  
    &:hover,
    &:active {
      color: $color-text-hover;
  
      svg {
        fill: $color-text-hover;
      }
    }
  
    &:focus-visible {
      color: $color-text-hover;
  
      svg {
        fill: $color-text-hover;
      }
  
      &:after {
        opacity: 1;
      }
    }
  
    &--space-sm {
      font-size: 14px;
    }
  
    &--space-lg {
      margin-left: -16px;
      padding: 12px 16px;
    }
  
    &--underline {
      background-color: transparent;
      text-decoration: underline;
  
      &:after {
        display: none;
      }
  
      &:hover,
      &:active {
        color: $color-secondary-hover;
      }
  
      &:focus-visible {
        color: $color-text-white;
        background-color: $color-secondary;
        text-decoration: none;
      }
    }
  
    svg {
      @include transition-fast;
      position: relative;
      top: 2px;
      fill: $color-secondary;
  
      & + span {
        margin-left: 6px;
      }
    }
  
    span + svg {
      top: 2px;
      margin-left: 12px;
    }
  
    &--invert {
      color: $color-tertiary;
  
      svg {
        fill: $color-tertiary;
      }
  
      &:hover,
      &:active {
        color: $color-secondary;
  
        svg {
          fill: $color-secondary;
        }
      }
    }
  }
  