@import "./assets/scss/style.scss";

.home {
  &__section {
    &-title {
      margin-bottom: 20px;
      font-size: 22px;
      font-weight: 900;
      line-height: 38px;
    }

    &--news,
    &--program-list,
    &--slider {
      padding-top: 44px;
    }

    &--list-vision {
      margin-top: 70px;

      .wrapper {
        padding-top: 32px;
        border-top: 1px solid $color-border-stone;
      }
    }

    &--banner-image,
    &--gallery-text {
      margin-top: 60px;
    }

    &--slider {
      .wrapper {
        overflow: hidden;
      }

      .banner-image {
        width: 100%;
      }
    }
  }

  &__news {
    &-list {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: $grid-row-2x-gap;
    }

    &-more {
      margin-top: 24px;
    }

    .button {
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        top: 2px;
      }
    }
  }

  &__events {
    padding-top: 50px;
  }

  &__program-list-more {
    margin-top: 24px;

    .button {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  @include size-md {
    &__section {
      &--list-vision {
        margin-top: 100px;
      }

      &--news,
      &--program-list {
        padding-top: 72px;
      }

      &--banner-image,
      &--gallery-text {
        margin-top: 110px;
      }
    }

    &__news {
      display: grid;
      grid-template-columns: 7fr 5fr;
      grid-column-gap: $grid-column-gap;

      &-list--two {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: $grid-column-gap;
        grid-template-areas: "header nav" "content content" "ad ad" "footer footer";

        .card-article {
          &:nth-of-type(1) {
            grid-area: header;
          }

          &:nth-of-type(2) {
            grid-area: nav;
          }

          &:nth-of-type(3) {
            grid-area: content;
          }

          &:nth-of-type(4) {
            grid-area: ad;
          }

          &:nth-of-type(5) {
            grid-area: footer;
          }

          &--large .card-article__image {
            height: 160px;
          }
        }
      }

      &-more {
        margin-top: 32px;

        .button {
          display: inline-block;

          svg {
            top: 3px;
          }
        }
      }
    }

    &__events {
      padding-top: 0;
    }

    &__program-list-more {
      margin-top: 24px;
      text-align: right;

      .button {
        display: inline-block;
      }
    }
  }
}
